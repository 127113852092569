import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../components/styles/seance.css';
import Layout from '../components/layout';
import HeaderRandom from '../components/headerRandom';
import Seo from '../components/seo';

const Acces = () => (
  <Layout>
    <Seo />
    <HeaderRandom />
    <div id='title'>
      <h1>P.N.L</h1>
    </div>

    <div className='container division'>
      <div className='row'>
        <div className='col-md-6 sticky'>
          <StaticImage src='../img/brain.jpg' placeholder='blurred' alt='brain' />
        </div>
        <div className='col-md-6'>
          <h2 className='title'>LA PROGRAMMATION NEUROLINGUISTIQUE</h2>
          <p>
            La P.N.L est un ensemble d’outils extraordinaires qui permet d’aider à avoir
            confiance en soi, être mieux dans sa peau ou tout simplement à vivre de manière épanouie.
          </p>
          <p>
            Elle vise la réussite des objectifs fixés tout simplement grâce à votre cerveau.
            Ce dernier mémorise les bons et les moins bons moments de votre vie.
            La P.N.L vous aidera à contourner ou remettre en place ces différentes phases vécues.
          </p>
          <p>
            Je suis praticienne certifiée en programmation neurolinguistique par le docteur Richard Bandler,
            Je me suis donc formée à la source, cette formation garantie un apprentissage en accord avec la méthode actuelle.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Acces;
